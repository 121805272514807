@import '~antd/dist/antd.css';

html, body, #root {
    margin: 0;
    height: 100%;
}

.mainview {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.weather-info {
    height: auto;
}

.weather-info .card {
    border-radius: 0 0 8px 8px;
}

.weather-info img {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
}

.weather-info .temperature {
    font-weight: bold;
}

.weather-info-inner {
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bathtemp-list {
    padding: 16px 0;
}

.bathtemp-list .outer-list-container {
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bathtemp-list .outer-list-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bathtemp-list .outer-list-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.bathtemp-list .list-container {
    display: inline-flex;
    flex-direction: row;
    padding: 0 16px;
}

.bathtemp-card:last-child {
    margin-right: 0;
}

.bathtemp-card {
    height: 180px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    margin-right: 16px;
    flex-basis: 320px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 320px;
}

.bathtemp-card .info {
    padding: 0 8px;
    background-color: white;
    border-radius: 0 8px 8px 0;
    position: absolute;
    left: 0;
    bottom: 16px;
    max-width: 210px;
}

.sensor-list {
    height: auto;
    padding: 16px;
}

.list-item-container {
    flex: 1;
    flex-direction: column;
    padding-left: 16px;
}

.list-item-container > * {
    margin: 0 !important;
}

.footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

@media screen and (max-width: 360px) {
    .bathtemp-card {
        flex-basis: 280px;
        width: 280px;
    }
}